<script lang="ts">
	import { fade } from "svelte/transition";

	export let disabled = false;
	export let roundedNone = false;
	export let bgClass = "",
		outerClass = "";

	let isOpen = false;

	export function open() {
		isOpen = true;
	}
	export function close() {
		isOpen = false;
	}

	let modal: HTMLDivElement;
</script>

{#if isOpen}
	<!-- content here -->
	<div>
		<button
			id="modal-background"
			class={bgClass +
				" fixed left-0 top-0 z-[100] h-screen w-screen bg-black opacity-40"}
			{disabled}
			on:click={() => close()}
			transition:fade|local={{ duration: 200 }}
		/>

		<div
			class="pointer-events-none fixed left-0 top-0 z-[101] flex h-screen w-screen items-center justify-center"
			transition:fade|local={{ duration: 200 }}
		>
			<div
				bind:this={modal}
				id="modal"
				class={outerClass +
					` flex ${roundedNone ? "" : "md:rounded-[53px]"} pointer-events-auto bg-white`}
			>
				<slot />
			</div>
		</div>
	</div>
{/if}

<style>
	#modal {
		box-shadow: 7px 10px 20px rgba(0, 0, 0, 0.25);
	}
</style>
